import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { navigate } from 'gatsby';

const NotFoundPage = () => {
  const [isRender404, setIsRender404] = useState(false);

  useEffect(() => {
    const pathName = window.location.href;
    if (pathName.includes('/x/')) {
      Cookie.set('shortCode', pathName.slice(pathName.indexOf('/x/') + 3));
      navigate('/profile');
      setIsRender404(false);
    }

    if (!pathName.includes('/x/')) {
      setIsRender404(true);
    }
  }, []);

  if (isRender404) {
    return (
      <>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </>
    );
  }

  return null;
};

export default NotFoundPage;
